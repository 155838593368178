<template>
  <v-container grid-list-md>
     <div class="my-4">
            <v-btn
                depressed
                color="teal darken-3"
                outlined
                @click="$router.back()"
                exact
                >一覧へ戻る</v-btn
            >
        </div>
    <h2 class="pa-2">サンプル編集</h2>
    <v-divider></v-divider>
    <v-row justify="center" align="center" class="mt-5">
      <v-col lg="10">
        <v-card color="grey lighten-5 py-5">
          <v-simple-table class="transparent">
            <tbody>
              <tr>
                <th width="20%" class="text-right pa-1">
                  氏名<span class="red--text">※</span>
                </th>
                <td>
                  <v-text-field
                    label="名前を入力してください。"
                    solo
                    flat
                    outlined
                    hide-details
                    color="indigo"
                    dense
                    class="mr-1"
                    v-model="sample.name"
                  ></v-text-field>
                  <div v-if="errors && errors.name" class="text-danger">
                    {{ errors.name[0] }}
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-right pa-1">
                  アクティブ
                </th>
                <td>
                  <v-checkbox
                    label="アクティブ"
                    color="indigo"
                    value="1"
                    hide-details
                    class="mt-0"
                    text-color="dark"
                    v-bind:false-value="0"
                    v-bind:true-value="1"
                    v-model="sample.is_active"
                  ></v-checkbox>
                  <div v-if="errors && errors.is_active" class="text-danger">
                    {{ errors.is_active[0] }}
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-right pa-1">
                  ステータス
                </th>
                <td>
                  <v-select
                    :items="statuses"
                    item-text="label"
                    item-value="id"
                    solo
                    flat
                    outlined
                    hide-details
                    dense
                    background-color="white"
                    v-model="sample.status"
                    label="ステータス未選択"
                  ></v-select>
                  <div v-if="errors && errors.status" class="text-danger">
                    {{ errors.status[0] }}
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-right pa-1">
                  タイプ
                </th>
                <td>
                  <v-radio-group
                    v-model="sample.type"
                    row
                    dense
                    class="black--text"
                    ><v-radio
                      v-for="type in types"
                      :key="type.id"
                      :label="type.label"
                      :value="type.id"
                      color="indigo"
                      class="mr-5"
                    ></v-radio>
                  </v-radio-group>
                  <div v-if="errors && errors.type" class="text-danger">
                    {{ errors.type[0] }}
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-right pa-1">
                  メモ
                </th>
                <td>
                  <v-textarea
                    outlined
                    background-color="white"
                    name="input-7-4"
                    label="メモ"
                    color="indigo"
                    hide-details
                    class="mb-3"
                    v-model="sample.memo"
                  ></v-textarea>
                  <div v-if="errors && errors.memo" class="text-danger">
                    {{ errors.memo[0] }}
                  </div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <div class="text-center mt-5 mb-6">
     
      <v-btn
        x-large
        dark
        color="green"
        class="font-weight-bold mx-3"
        @click="submit"
        :disabled="buttonStatus"
        >保存する</v-btn
      ><br />
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      sample: {},
      errors: {},
      statuses: [],
      types: [],
      buttonStatus: false,
    };
  },
  mounted() {
     this.$store.commit("setOverlay", {
              overlay: true,
            });
    (async () => {
      //先にマスターデータを取得しておく。
      await this.$axios.get("/api/masters/statuses").then((response) => {
        this.statuses = response.data;
      });

      //先にマスターデータを取得しておく。
      await this.$axios.get("/api/masters/types").then((response) => {
        this.types = response.data;
      });
      this.$axios
        .get(`/api/samples/${this.$route.params.id}`)
        .then((response) => {
          this.sample = response.data;
           this.$store.commit("setOverlay", {
              overlay: false,
            });
        })
        .catch((error) => {
            if (error.response.status == "404") {
                  this.$router.replace({
                      path: "/404",
                  });
              }
      });
    })();
  },

  methods: {
    submit() {
      this.buttonStatus = true;
      this.errors = {};
      this.$axios
        .put(`/api/samples/${this.$route.params.id}`, this.sample)
        .then((response) => {
          if (response.data.result == true) {
            this.buttonStatus = true;
            this.$store.commit("setMessege", {
              text: response.data.message,
              color: "success",
            });
            this.$router.back()
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
            this.$store.commit("setMessege", {
                            text: '入力内容にエラーがあります。内容を確認してください。',
                            color: "warning",
                        });
            this.buttonStatus = false;
          }
        });
    },
  },
};
</script>

<style type="scss" scoped>
table th,
table td {
  padding: 1em !important;
}
</style>
